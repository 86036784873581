import { PAYMENT_TYPE_ALL } from "../constants/Dashboard";
import { IFilterDashboard } from "../pages/dashboard/Dashboard";

export const getDashboardFilter = (dashboardFilters: IFilterDashboard) => {
    let filter = dashboardFilters.paymentType ? `paymentType=${dashboardFilters.paymentType}` : `paymentType=${PAYMENT_TYPE_ALL}`;
    if (dashboardFilters.merchantName) {
        filter += `&merchantName=${encodeURIComponent(dashboardFilters.merchantName)}`;
    }
    if (dashboardFilters.merchantId) {
        filter += `&merchantId=${encodeURIComponent(dashboardFilters.merchantId)}`;
    }
    if (dashboardFilters.merchantWebsite) {
        filter += `&merchantWebsite=${encodeURIComponent(dashboardFilters.merchantWebsite)}`;
    }
    if (dashboardFilters.merchantAgregator) {
        filter += `&merchantAgregator=${encodeURIComponent(dashboardFilters.merchantAgregator)}`;
    }
    if (dashboardFilters.merchantMcc) {
        filter += `&merchantMcc=${encodeURIComponent(dashboardFilters.merchantMcc)}`;
    }
    
    return filter;
};
