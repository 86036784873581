import { useEffect, useMemo, useState } from 'react';
import {
    useGetAmountPerHourChartQuery,
    useGetAvgAmountPerHourChartQuery,
    useGetPerDayAvgAmountChartQuery,
    useGetPerDayCountChartQuery,
    useGetPerDayPercentChartQuery,
    useGetPerDayPercentForMerchantChartQuery,
    useGetPerHourCountChartQuery,
} from '../../redux/api/reportsApi';
import { Button, Col, notification, Row, Select } from 'antd';
import { Column, Datum } from '@ant-design/charts';
import { BASE_NOTIFICATION_CONFIG, DATE_FORMAT_FOR_QUERY } from '../../constants';
import dayjs, { Dayjs } from 'dayjs';
import PieGraph from './components/PieGraph';
import ColumnGraph from './components/ColumnGraph';
import { numberToInputString } from '../../helpers/numberToInputString';
import { PAYMENT_TYPE_ALL, PAYMENT_TYPE_P2P, PAYMENT_TYPE_PURCHASE } from '../../constants/Dashboard';
import { useGetMerchantsQuery } from '../../redux/api/dictionaryApi';
import { SyncOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import DoublePieGraph from './components/DoublePieGraph';

const sliderConfig = {
    start: 0,
    end: 1,
};

const today = dayjs().startOf('day');
const subtractFromToday = today.subtract(4, 'day');

const rowHeight = 210;
export const countTooltipSettings = {
    formatter: (datum: Datum) => ({ name: 'всього', value: datum.count }),
};
const amountTooltipSettings = {
    formatter: (datum: Datum) => ({ name: 'сума', value: datum.amount }),
};
export const avgAmountTooltipSettings = {
    formatter: (datum: Datum) => ({ name: 'сума', value: numberToInputString(+datum.amount, 2) }),
};
export interface IPeriod {
    start?: Dayjs;
    end?: Dayjs;
}

export interface IPieFilter {
    start?: Dayjs;
    end?: Dayjs;
    merchantName?: string;
}

export const yAxis = {
    label: {
        formatter: (v: string | number) => {
            let val = v as number;
            if (val > 999999) {
                return `${(val / 1000000).toFixed(1)} млн.`;
            }
            if (val > 999) {
                return `${(val / 1000).toFixed(1)} тис.`;
            }
            return val;
        },
    },
};

export interface IFilterDashboard {
    paymentType?: string;
    merchantId?: string;
    merchantName?: string;
    merchantWebsite?: string;
    merchantAgregator?: string;
    merchantMcc?: string;
}
const paymentTypeOptions = [
    { label: 'Всі', value: PAYMENT_TYPE_ALL },
    { label: 'Purchase', value: PAYMENT_TYPE_PURCHASE },
    { label: 'P2PCredit', value: PAYMENT_TYPE_P2P },
];

export const initialPeriodOneMonth = { start: today.subtract(1, 'month'), end: today };
export const initialPeriodOneDay = { start: today, end: today };
export const initialMerchantPercentFilter = { start: today.subtract(1, 'month'), end: today, merchantName: '' };
const initialFilter: IFilterDashboard = {
    paymentType: PAYMENT_TYPE_ALL,
    merchantId: '',
    merchantName: '',
    merchantWebsite: '',
    merchantAgregator: '',
    merchantMcc: '',
};
const Dashboard = () => {
    const [perDayPercentPeriod, setPerDayPercentPeriod] = useState<IPeriod>(initialPeriodOneDay);
    const [perDayCountPeriod, setPerDayCountPeriod] = useState<IPeriod>(initialPeriodOneMonth);
    const [perDayAvgAmountPeriod, setPerDayAvgAmountPeriod] = useState<IPeriod>(initialPeriodOneMonth);
    const [merchantPercentFilter, setMerchantPercentFilter] = useState<IPieFilter>(initialMerchantPercentFilter);
    const [filter, setFilter] = useState<IFilterDashboard>({ ...initialFilter });
    const [appliedFilter, setAppliedFilter] = useState<IFilterDashboard>({ ...initialFilter });
    const { data: merchantsDict } = useGetMerchantsQuery();
    const handleApplyFilters = () => {
        setAppliedFilter(filter);
    };
    const handleResetFilters = () => {
        setFilter({ ...initialFilter });
        setAppliedFilter({ ...initialFilter });
    };
    const merchantIdOptions = useMemo(
        () =>
            merchantsDict
                ? [
                      { label: 'Всі', value: '' },
                      ...new Set(
                          merchantsDict?.map((item) => {
                              return { label: item.merchantId, value: item.merchantId };
                          }),
                      ),
                  ]
                : [],
        [merchantsDict],
    );
    const merchantNameOptions = useMemo(
        () =>
            merchantsDict
                ? [
                      { label: 'Всі', value: '' },
                      ...new Set(
                          merchantsDict?.map((item) => {
                              return { label: item.name, value: item.name };
                          }),
                      ),
                  ]
                : [],
        [merchantsDict],
    );
    const merchantAgregatorOptions = useMemo(
        () =>
            merchantsDict
                ? [
                      { label: 'Всі', value: '' },
                      ...new Set(
                          merchantsDict?.map((item) => {
                              return { label: item.agregator, value: item.agregator };
                          }),
                      ),
                  ]
                : [],
        [merchantsDict],
    );
    const merchantMccOptions = useMemo(
        () =>
            merchantsDict
                ? [
                      { label: 'Всі', value: '' },
                      ...new Set(
                          merchantsDict?.map((item) => {
                              return { label: item.mcc, value: item.mcc };
                          }),
                      ),
                  ]
                : [],
        [merchantsDict],
    );
    const merchantWebsiteOptions = useMemo(
        () =>
            merchantsDict
                ? [
                      { label: 'Всі', value: '' },
                      ...new Set(
                          merchantsDict?.map((item) => {
                              return { label: item.website, value: item.website };
                          }),
                      ),
                  ]
                : [],
        [merchantsDict],
    );
    const {
        data: perHourCount,
        error: errorHourCount,
        refetch: refetchPerHour,
    } = useGetPerHourCountChartQuery({ filterDashboard: appliedFilter });
    const {
        data: perDayCount,
        error: errorDayCount,
        refetch: refetchPerDay,
    } = useGetPerDayCountChartQuery({
        startDate: subtractFromToday.format(DATE_FORMAT_FOR_QUERY),
        endDate: today.format(DATE_FORMAT_FOR_QUERY),
        filterDashboard: appliedFilter,
    });
    const {
        data: perDayPercent,
        error: errorDayPercent,
        refetch: refetchPerDayPercent,
    } = useGetPerDayPercentChartQuery(
        {
            startDate: perDayPercentPeriod.start?.format(DATE_FORMAT_FOR_QUERY) as string,
            endDate: perDayPercentPeriod.end?.format(DATE_FORMAT_FOR_QUERY) as string,
            filterDashboard: appliedFilter,
        },
        { skip: !perDayPercentPeriod.start || !perDayPercentPeriod.end },
    );
    const {
        data: perDayMerchantPercent,
        error: errorDayMerchantPercent,
        refetch: refetchPerDayMerchantPercent,
    } = useGetPerDayPercentForMerchantChartQuery(
        {
            startDate: merchantPercentFilter.start?.format(DATE_FORMAT_FOR_QUERY) as string,
            endDate: merchantPercentFilter.end?.format(DATE_FORMAT_FOR_QUERY) as string,
            merchantName: merchantPercentFilter.merchantName as string,
        },
        { skip: !perDayPercentPeriod.start || !perDayPercentPeriod.end },
    );
    const {
        data: perDayCountWithPeriod,
        error: errorDayCountWithPeriod,
        refetch: refetchPerDayWithCount,
    } = useGetPerDayCountChartQuery(
        {
            startDate: perDayCountPeriod.start?.format(DATE_FORMAT_FOR_QUERY) as string,
            endDate: perDayCountPeriod.end?.format(DATE_FORMAT_FOR_QUERY) as string,
            filterDashboard: appliedFilter,
        },
        { skip: !perDayCountPeriod.start || !perDayCountPeriod.end },
    );
    const {
        data: perDayAvgAmountWithPeriod,
        error: errorDayAvgAmountWithPeriod,
        refetch: refetchPerDayWithAvgAmount,
    } = useGetPerDayAvgAmountChartQuery(
        {
            startDate: perDayAvgAmountPeriod.start?.format(DATE_FORMAT_FOR_QUERY) as string,
            endDate: perDayAvgAmountPeriod.end?.format(DATE_FORMAT_FOR_QUERY) as string,
            filterDashboard: appliedFilter,
        },
        { skip: !perDayAvgAmountPeriod.start || !perDayAvgAmountPeriod.end },
    );
    const {
        data: avgAmountPerHour,
        error: errorAvgAmountPerHour,
        refetch: refetchAvgAmountPerHour,
    } = useGetAvgAmountPerHourChartQuery({
        date: today.format(DATE_FORMAT_FOR_QUERY),
        filterDashboard: appliedFilter,
    });
    const {
        data: amountPerHour,
        error: errorAmountPerHour,
        refetch: refetchAmountPerHour,
    } = useGetAmountPerHourChartQuery({
        date: today.format(DATE_FORMAT_FOR_QUERY),
        filterDashboard: appliedFilter,
    });
    const reload = () => {
        refetchPerHour();
        refetchPerDay();
        refetchPerDayWithCount();
        refetchPerDayPercent();
        refetchAmountPerHour();
        refetchAvgAmountPerHour();
        refetchPerDayWithAvgAmount();
        refetchPerDayMerchantPercent();
    };

    useEffect(() => {
        if (errorHourCount) {
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: (errorHourCount as any)?.data?.message,
            });
        }
    }, [errorHourCount]);

    useEffect(() => {
        if (errorDayCount) {
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: (errorDayCount as any)?.data?.message,
            });
        }
    }, [errorDayCount]);

    useEffect(() => {
        if (errorDayPercent) {
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: (errorDayPercent as any)?.data?.message,
            });
        }
    }, [errorDayPercent]);

    useEffect(() => {
        if (errorDayCountWithPeriod) {
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: (errorDayCountWithPeriod as any)?.data?.message,
            });
        }
    }, [errorDayCountWithPeriod]);

    useEffect(() => {
        if (errorAmountPerHour) {
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: (errorAmountPerHour as any)?.data?.message,
            });
        }
    }, [errorAmountPerHour]);

    useEffect(() => {
        if (errorAvgAmountPerHour) {
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: (errorAvgAmountPerHour as any)?.data?.message,
            });
        }
    }, [errorAvgAmountPerHour]);

    useEffect(() => {
        if (errorDayAvgAmountWithPeriod) {
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: (errorDayAvgAmountWithPeriod as any)?.data?.message,
            });
        }
    }, [errorDayAvgAmountWithPeriod]);

    useEffect(() => {
        if (errorDayMerchantPercent) {
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: (errorDayMerchantPercent as any)?.data?.message,
            });
        }
    }, [errorDayMerchantPercent]);
    return (
        <Row gutter={[24, 24]} className="dashboard__wrapper">
            <Col span={24}>
                <Row align="bottom" gutter={[24, 24]}>
                    <Col>
                        <div className="rules-filter__item">
                            <label htmlFor="activity">Фільтр типу транзакцій</label>
                            <Select
                                className="dashboard-filter"
                                value={filter.paymentType}
                                onChange={(value) => setFilter({ ...filter, paymentType: value })}
                                options={paymentTypeOptions}
                                id="activity"
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="rules-filter__item">
                            <label htmlFor="activity">Merchant ID</label>
                            <Select
                                className="dashboard-filter"
                                value={filter.merchantId}
                                onChange={(value) => setFilter({ ...filter, merchantId: value })}
                                options={merchantIdOptions}
                                id="activity"
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="rules-filter__item">
                            <label htmlFor="activity">Merchant Name</label>
                            <Select
                                className="dashboard-filter"
                                value={filter.merchantName}
                                onChange={(value) => setFilter({ ...filter, merchantName: value })}
                                options={merchantNameOptions}
                                id="activity"
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="rules-filter__item">
                            <label htmlFor="activity">Merchant Webiste</label>
                            <Select
                                className="dashboard-filter"
                                value={filter.merchantWebsite}
                                onChange={(value) => setFilter({ ...filter, merchantWebsite: value })}
                                options={merchantWebsiteOptions}
                                id="activity"
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="rules-filter__item">
                            <label htmlFor="activity">Merchant Agregator</label>
                            <Select
                                className="dashboard-filter"
                                value={filter.merchantAgregator}
                                onChange={(value) => setFilter({ ...filter, merchantAgregator: value })}
                                options={merchantAgregatorOptions}
                                id="activity"
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="rules-filter__item">
                            <label htmlFor="activity">Merchant MCC</label>
                            <Select
                                className="dashboard-filter"
                                value={filter.merchantMcc}
                                onChange={(value) => setFilter({ ...filter, merchantMcc: value })}
                                options={merchantMccOptions}
                                id="activity"
                            />
                        </div>
                    </Col>
                    <Col>
                        <Button icon={<CheckOutlined />} onClick={handleApplyFilters}>
                            Застосувати фільтри
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<CloseOutlined />} onClick={handleResetFilters}>
                            Скинути фільтри
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<SyncOutlined />} onClick={reload}>
                            Оновити
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Row gutter={24}>
                            <Col span={12} className="dashboard__text-center">
                                <h3>Загальна кількість транзакцій (подобово)</h3>
                            </Col>
                            <Col span={12} className="dashboard__text-center">
                                <h3>Співвідношення успішних та відмовних транзакцій (подобово) за період</h3>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Column
                                    data={perDayCount || []}
                                    height={rowHeight + 48}
                                    xField="day"
                                    yField="count"
                                    tooltip={countTooltipSettings}
                                    yAxis={yAxis}
                                />
                            </Col>
                            <Col span={12}>
                                <PieGraph height={rowHeight} data={perDayPercent} onChangePeriod={setPerDayPercentPeriod} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row gutter={[24, 24]}>
                    <Col span={24} className="dashboard__text-center">
                        <h3>Транзакції за 1 день (почасово)</h3>
                    </Col>
                    <Col span={24}>
                        <Column
                            height={rowHeight}
                            data={perHourCount || []}
                            xField="hour"
                            yField="count"
                            tooltip={countTooltipSettings}
                            slider={sliderConfig}
                            yAxis={yAxis}
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row gutter={[24, 24]}>
                    <Col span={24} className="dashboard__text-center">
                        <h3>Суми транзакцій за 1 день (почасово)</h3>
                    </Col>
                    <Col span={24}>
                        <Column
                            height={rowHeight}
                            data={amountPerHour || []}
                            xField="hour"
                            yField="amount"
                            slider={sliderConfig}
                            yAxis={yAxis}
                            tooltip={amountTooltipSettings}
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row gutter={[24, 24]}>
                    <Col span={24} className="dashboard__text-center">
                        <h3>Середня сума транзакцій за 1 день (почасово)</h3>
                    </Col>
                    <Col span={24}>
                        <Column
                            height={rowHeight}
                            data={avgAmountPerHour || []}
                            xField="hour"
                            yField="amount"
                            slider={sliderConfig}
                            yAxis={yAxis}
                            tooltip={amountTooltipSettings}
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row gutter={[24, 24]}>
                    <Col span={24} className="dashboard__text-center">
                        <h3>Загальна кількість транзакцій (подобово) за період</h3>
                    </Col>
                    <Col span={24}>
                        <ColumnGraph
                            height={rowHeight}
                            onChangePeriod={setPerDayCountPeriod}
                            data={perDayCountWithPeriod}
                            isCount={true}
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row gutter={[24, 24]}>
                    <Col span={24} className="dashboard__text-center">
                        <h3>Середня сума транзакцій (подобово) за період</h3>
                    </Col>
                    <Col span={24}>
                        <ColumnGraph
                            height={rowHeight}
                            onChangePeriod={setPerDayAvgAmountPeriod}
                            data={perDayAvgAmountWithPeriod}
                            isCount={false}
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row gutter={24}>
                    <Col span={24} className="dashboard__text-center">
                        <h3>Співвідношення суми і кількості транзакцій по мерчанту</h3>
                    </Col>
                    <DoublePieGraph
                        height={rowHeight}
                        data={perDayMerchantPercent}
                        merchantsOptions={merchantNameOptions}
                        onChangePieFilter={setMerchantPercentFilter}
                    />
                </Row>
            </Col>
        </Row>
    );
};

export default Dashboard;
